<template>
  <div class="s-page">
    <div class="s-header">
      <van-nav-bar
        :title="$t('tix.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent s-content">
      <van-form v-model="form">
        <van-row>
          <van-cell :title="$t('tix.biz')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="bizhong" :options="bzoptions" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-cell :title="$t('tix.lname')" center>
            <template #extra>
              <van-dropdown-menu active-color="#004ea3">
                <van-dropdown-item v-model="productvalue" :options="product" />
              </van-dropdown-menu>
            </template>
          </van-cell>
          <van-field
            v-model="form.url"
            :label="$t('tix.address')"
            :placeholder="$t('tix.enter')"
          />
          <van-field :label="$t('tix.number')" v-model="form.number" clearable
            ><template #button>
              <van-button size="small" type="primary" class="s-btn" @click.prevent="all">{{
                $t('tix.allbtn')
              }}</van-button>
            </template>
          </van-field>
        </van-row>
        <div class="explain">{{ $t('tix.text') }}{{ balance }}</div>
      </van-form>
    </div>
    <div class="s-footer">
      <div class="submitBlock">
        <van-button class="s-submit-btn" block @click.prevent="submit">
          {{ $t('common.submit') }}
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        url: '',
        number: ''
      },
      productvalue: 0,
      product: [],
      bizhong: 1,
      bzoptions: [{ text: 'USDT', value: 1 }],
      balance: 0,
      config: {}
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/user/withdraw')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance
          this.wallet = data.data.type
          this.config = data.data.config
          this.product = []
          this.wallet.forEach((type, index) => {
            this.product.push({
              text: type.wallet_type.toUpperCase(),
              value: index
            })
          })
          if (this.config.withdrawl_opend === '0') {
            this.$toast.fail(this.$t('tix.erroropen'))
          }
          if (!this.config.attime) {
            this.$toast.fail(
              this.$t('tix.errortime') + this.config.withdrawl_times
            )
          }
        }
      }
    },
    all() {
      this.form.number = this.balance
    },
    async submit() {
      if (!this.form.url || !this.form.number) {
        this.$toast.fail(this.$t('tix.complate'))
        return false
      }
      const postform = {
        product_type: this.wallet[this.productvalue].product_type,
        product_type_id: this.wallet[this.productvalue].product_type_id,
        withdraw_url: this.form.url,
        withdraw_num: this.form.number,
        service_charge: this.config.withdrawl_fee
      }
      const { data } = await this.$http.post('/home/user/withdraw', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('tix.submitok'))
          this.form = {
            url: '',
            number: 0
          }
          this.getinfo()
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-cell__title {
    text-align: left;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
    width: initial;
  }
  .van-cell {
    font-size: 0.98667rem;
    background-color: #fff !important;
    height: 3.46667rem;

    &:after {
      border-bottom: 0.02667rem solid #d0d0d1;
    }
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
  .van-field {
    height: 3.4rem;
    font-size: 0.93333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /deep/.van-field__label {
      color: #323232;
      width: initial;
    }
  }
  .van-cell__value {
    position: relative;
    overflow: hidden;
    color: #969799;
    text-align: right;
    vertical-align: middle;
    word-wrap: break-word;
    .s-btn {
      background: #004EA3;
      border-radius: 0.3125rem;
      border-color: #004EA3;
    }
  }
  /deep/.van-field__error-message--right,
  /deep/.van-field__control,
  /deep/.van-field__error-message {
    text-align: right;
  }
  .explain {
    text-align: left;
    font-weight: 500;
    font-size: 0.75rem;
    color: #999999;
    padding: 0.75rem 0.65625rem;
  }
  .message {
    margin-top: 0.8rem;
    font-size: 1.06667rem;
    color: #000;
  }
}
.submitBlock {
  padding: 1.5625rem;
  .s-submit-btn {
    background: #004EA3;
    border-radius: 0.3125rem;
    font-weight: 500;
    font-size: 1.125rem;
    color: #FFFFFF;
  }
}
</style>
